<template>
  <RightSideBar
    v-if="openModal"
    @close="$emit('close')"
    @submit="saveData"
    :submit="'Save'"
    :loading="loading"
    button-class="bg-dynamicBackBtn text-white"
  >
   <template v-slot:title>
        <p class="text-darkPurple">
          Loans & Advances Settings
        </p>
      </template>
      <template v-slot:subtitle>
        <h5> Global Setting</h5>
        <p class="font-semibold text-darkPurple">
          Setup the policy for advance and loan applications
        </p>
      </template>

          <div class="mt-4">
            <div class="text-sm text-jet">
              Rate Type
            </div>
           <c-text
              placeholder="Enter Rate Value"
              variant="w-full"
              :value="'% of Gross Earnings'"
              :disabled="true"
            />
          </div>
          <div class="mt-4">
            <div class="text-sm text-jet">
              Rate Value
            </div>
            <c-text
              placeholder="Enter Rate Value"
              variant="w-full"
              v-model="setting.maxLoanAndAdvanceRequest"
            />
          </div>
  </RightSideBar>
</template>


<script>

import CText from '@scelloo/cloudenly-ui/src/components/text';
 import RightSideBar from '@/components/RightSideBar';

export default {
  name: "GlobalAdvancceAndLoans",
  components: {
    RightSideBar,
    CText
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      setting: {
        maxLoanAndAdvanceRequest: '',
        orgId: ''
      }
    };
  },
  watch: {},
  computed: {

  },
  methods: {
    invalid_permission() {
      this.$store.commit("SET_TOAST", {
        text: "You Do not have the required permission",
        color: "error",
      });
    },
    async getSetting() {
      try {
         this.loading = true
        const response = await this.$_getGeneralAdvanceSettings();
        this.setting = response.data.setting;
        this.loading = false
      // eslint-disable-next-line no-empty
      } catch (error){}

    },
    saveData() {

      this.loading = false;
      this.$_AdvanceSettings(this.setting)
        .then(() => {
          this.loading = false;
          this.$emit('save-advance-and-loan', this.setting.maxLoanAndAdvanceRequest);
        })
        .catch(() => {
          this.loading = false;
        });
    },

  },
  async mounted() {
    await this.getSetting();
  },
};
</script>

<style scoped>

</style>
