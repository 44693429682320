<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.push({
            name: 'PaySettings',
            query: { payType: 'Earnings' }
          })
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 220px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          {{ isEditState ? "Edit Earnings" : "New Earnings" }}
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="px-3">
      <ValidationObserver rules="required" v-slot="{ handleSubmit }">
        <card class="p-5 mt-6">
          <p class="text-lg font-bold">Earning Details</p>
          <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
            <div class="col-span-4">
              <div class="text-sm text-jet">Pay Item</div>
              <c-select
                placeholder="--Select--"
                :options="payItemList"
                :disabled="isEditState"
                v-model="earningCredential.payItemId"
                @onChange="handlePayItem($event)"
                variant="w-full"
              />
              <div
                v-if="
                  earningCredential.payItemSlug === 'advance' ||
                    earningCredential.payItemSlug === 'loan'
                "
              >
                <div class="text-sm text-gray-500">
                  Global Advance and Loan Limit is {{ advanceAndLoanLimit }}% of
                  Annual Gross.
                  <button
                    @click="openAdvanceAndLoansSidebar = true"
                    class="font-bold text-blue-500"
                  >
                    Change limit
                  </button>
                </div>
              </div>
            </div>
            <div class="col-span-4 label">
              <c-text
                placeholder="Enter"
                variant="w-full"
                label="Name in Payslip"
                :rules="payslipRule"
                v-model="earningCredential.nameOnPayslip"
              />
            </div>
            <div class="col-span-4">
              <div class="text-sm text-jet">Earning Type</div>
              <c-select
                placeholder="--Select--"
                :options="payItemType"
                variant="w-full"
                v-model="earningCredential.payType"
              />
            </div>
            <div class="col-span-12">
              <div class="text-base text-jet">
                Include pay item in annual gross pay?
              </div>
              <radio-button
                :options="grossPayArray"
                col-span="col-span-1"
                class="mt-4 text-darkPurple pl-4"
                row-gap="gap-y-1"
                v-model="earningCredential.includedInGrossPay"
                :disabled="isDisabled"
              />
            </div>
            <div class="col-span-4">
              <div class="text-sm text-jet">Rate Type</div>
              <c-select
                placeholder="--Select--"
                :options="rateTypes"
                variant="w-full"
                @onChange="handleRateType($event)"
                v-model="earningCredential.rateTypeSlug"
                :disabled="disabledRateType"
              />
            </div>
            <div class="col-span-4">
              <div
                v-if="earningCredential.payItemSlug === 'advance'"
                class="text-sm font-extrabold text-jet"
              >
                <c-text
                  placeholder="Enter Advance Limit"
                  variant="w-full"
                  type="number"
                  label="Advance Limit(%)"
                  @input="updateLevelsValue"
                  v-model="rateValue"
                />
              </div>
              <div
                v-else-if="earningCredential.payItemSlug === 'loan'"
                class="text-sm text-jet"
              >
                <c-text
                  placeholder="Enter Loan Limit"
                  variant="w-full"
                  type="number"
                  label="Loan Limit(%)"
                  @input="updateLevelsValue"
                  v-model="rateValue"
                />
              </div>
              <c-text
                placeholder="Enter Rate Value"
                variant="w-full"
                v-else
                :errors="error"
                type="number"
                label="Rate Value"
                @input="updateLevelsValue"
                v-model="rateValue"
              />
            </div>
            <div class="col-span-12" />
            <div
              v-if="earningCredential.payType === 'variable'"
              class="col-span-8 -mt-7"
            >
              <div class="text-base text-jet">Payable when?</div>
              <radio-button
                :options="requestArray"
                col-span="col-span-5"
                class="m-4 text-darkPurple"
                @change="handlePayableRequestChange"
                row-gap="gap-y-1"
                :disabled="isDisabledRequest"
                v-model="isPayRequest"
              />
              <div class="flex">
                <div class="pl-5 w-5/12 flex items-end">
                  <c-select
                    v-if="isPayRequest === 'true'"
                    placeholder="--Cutoff Date--"
                    label="Payment Cutoff date"
                    :options="daysArray"
                    class=" w-2/5"
                    v-model="earningCredential.payrollCutoffDay"
                  />
                  <span v-if="isPayRequest === 'true'" class="p-2">
                    of every month.</span
                  >
                </div>
                <div class="pl-0 w-5/12">
                  <c-select
                    v-if="isPayRequest === 'false'"
                    placeholder="--Month--"
                    :options="monthsArray"
                    :rules="['required']"
                    variant="w-full"
                    v-model="earningCredential.payMonth"
                  />
                </div>
              </div>
            </div>
          </div>
        </card>
        <card
          class="p-5 mt-6"
          v-if="
            earningCredential.payItemSlug === 'advance' ||
              earningCredential.payItemSlug === 'loan'
          "
        >
          <p class="text-lg font-bold">Advance And Loan</p>
          <p class="mt-4 mb-2">
            When is an employee entitled to this advance or loan?
          </p>
          <div class="grid grid-cols-12 gap-7">
            <div class="col-span-12">
              <radio-button
                :options="eligibilityArray"
                col-span="col-span-3"
                class="mx-4 text-darkPurple"
                row-gap="gap-y-1"
                v-model="isAppointment"
              />
            </div>
            <div class="col-span-12">
              <div class="text-base text-jet">
                <checkbox
                  :options="taxableArray"
                  col-span="col-span-12"
                  class="text-darkPurple leading-none px-5"
                  label=" No Outstanding Advances & Loans"
                />
              </div>
            </div>
            <div class="col-span-4">
              <div class="text-sm text-jet">
                Total Monthly Limit (Organisation)
              </div>
              <c-text
                placeholder="Enter"
                v-model="earningCredential.advanceMonthlyLimit"
                variant="w-full"
              />
            </div>

            <div class="col-span-3">
              <div class="text-sm text-jet">Max Repayment Duration</div>
              <div class="flex space-x-2">
                <div class="w-6/12">
                  <c-text
                    placeholder=""
                    v-model="earningCredential.advanceRepaymentDuration"
                    variant="w-full"
                  />
                </div>
                <div class="w-6/12">
                  <span class="pt-3 block">Month(s)</span>
                </div>
              </div>
            </div>
            <div class="col-span-12 mmb-0">
              <div class="text-base text-jet">Is this Interest based?</div>
              <radio-button
                :options="taxableArray"
                col-span="col-span-1"
                class="m-4 mb-0 text-darkPurple"
                v-model="earningCredential.advanceIsInterestBased"
              />
            </div>
            <div
              v-if="
                earningCredential.advanceIsInterestBased === 'true' ||
                  earningCredential.advanceIsInterestBased === true
              "
              class="col-span-4 mt-0"
            >
              <div class="text-base text-jet">Interest(%)</div>
              <c-text
                placeholder="Interest value"
                v-model="earningCredential.interest"
              />
            </div>
          </div>
        </card>

        <card v-if="hasLevels" class="p-5 mt-6">
          <p class="text-lg font-bold">Configure By Job Level</p>
          <span class="block py-2">
            Select a job level and configure the pay settings applicable.
          </span>
          <div
            class="flex flex-col"
            style="margin-top:1.125rem; overflow: scroll"
          >
            <div class="w-full" style="overflow: scroll">
              <Tab
                :tabs="levels"
                :select-key="'name'"
                border
                :active-tab="activeTab"
                @currentTab="handleLevel"
              />
            </div>
            <div class="pt-4">
              <div class="grid grid-cols-12 gap-7 pt-4">
                <div class="col-span-4">
                  <div class="text-sm text-jet">Rate Type</div>
                  <c-text
                    placeholder=""
                    variant="w-full"
                    disabled
                    v-model="activeTab.rateType"
                  />
                </div>
                <div class="col-span-4">
                  <div
                    v-if="earningCredential.payItemSlug === 'advance'"
                    class="text-sm text-jet"
                  >
                    Advance Limit
                  </div>
                  <div
                    v-else-if="earningCredential.payItemSlug === 'loan'"
                    class="text-sm text-jet"
                  >
                    Loan Limit
                  </div>
                  <div v-else class="text-sm text-jet">Rate Value</div>
                  <c-text
                    placeholder=""
                    variant="w-full"
                    :disabled="disabledLevelRateValue"
                    type="number"
                    v-model="activeTab.rateValue"
                  />
                </div>
              </div>
            </div>
          </div>
        </card>
        <card class="p-5 mt-6">
          <p class="text-lg font-bold">Additional Settings</p>
          <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
            <div class="col-span-3">
              <div class="text-base text-jet">
                Mark this Pay Item As Active?
              </div>
              <radio-button
                :options="activeArray"
                col-span="col-span-5"
                class="m-4 text-darkPurple"
                row-gap="gap-y-1"
                :disabled="disableIsActive"
                v-model="earningCredential.isActive"
              />
            </div>
            <div class="col-span-3">
              <div class="text-base text-jet">
                Include in Total Employee Cost?
              </div>
              <radio-button
                :options="costArray"
                col-span="col-span-5"
                class="m-4 text-darkPurple"
                row-gap="gap-y-1"
                v-model="earningCredential.includedInTotalEmpCost"
              />
            </div>
            <div class="col-span-3">
              <div class="text-base text-jet">Is this a Taxable Earning?</div>
              <radio-button
                :options="earningArray"
                col-span="col-span-5"
                class="m-4 text-darkPurple"
                row-gap="gap-y-1"
                v-model="earningCredential.isTaxable"
              />
            </div>
            <div class="col-span-3">
              <div class="text-base text-jet">
                Calculate on a Pro-rated Basis?
              </div>
              <radio-button
                :options="basisArray"
                col-span="col-span-5"
                class="m-4 text-darkPurple"
                row-gap="gap-y-1"
                v-model="earningCredential.calculateProrata"
              />
            </div>
            <div class="col-span-4">
              <div class="text-base text-jet">
                Deduct Employee Pension contribution?
              </div>
              <radio-button
                :options="contributionArray"
                col-span="col-span-5"
                class="m-4 text-darkPurple"
                :disabled="
                  earningCredential.payItemSlug === 'basic' ||
                    earningCredential.payItemSlug === 'transportAllowance' ||
                    earningCredential.payItemSlug === 'housingAllowance'
                "
                row-gap="gap-y-1"
                v-model="earningCredential.isPartOfPension"
              />
            </div>
            <div v-feature-request class="col-span-12">
              <div class="text-base text-jet">
                <checkbox
                  :options="taxableArray"
                  col-span="col-span-12"
                  class="text-darkPurple leading-none px-5"
                  v-model="earningCredential.addsToDecember"
                  label="If not requested
                 as at November 30th, automatically add to December’s payroll "
                />
              </div>
            </div>
          </div>
        </card>
        <div class="flex my-5 float-right">
          <div class="flex">
            <Button
              class="inline-block bg-dynamicBackBtn text-white buttons rounded-md"
              :disabled="creatingEarnings"
              @click="handleSubmit(saveEarnings)"
            >
              Save
            </Button>
            <Button
              @click="
                $router.push({
                  name: 'PaySettings',
                  query: { payType: 'Earnings' }
                })
              "
              class="buttons inline-block"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <global-advance-and-loans
      :open-modal="openAdvanceAndLoansSidebar"
      @close="openAdvanceAndLoansSidebar = false"
      @save-advance-and-loan="setAdvanceAndLoanLimit"
    />
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Card from "@/components/Card";
import RadioButton from "@/components/RadioButton";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";
import GlobalAdvanceAndLoans from "./GlobalAdvanceAndLoans";

export default {
  name: "NewEarnings",
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    Card,
    CText,
    CSelect,
    RadioButton,
    Checkbox,
    Tab,
    Button,
    GlobalAdvanceAndLoans
  },
  data() {
    return {
      isEditState: !!this.$route.params.id,
      openAdvanceAndLoansSidebar: false,
      advanceAndLoanLimit: "",
      isVisible: false,
      isAppointment: false,
      payItemList: [],
      isPayRequest: "false",
      dateVisible: "true",
      // monthVisible: false,
      isShowMonth: false,
      isDisabledRequest: false,
      isDisabled: true,
      creatingEarnings: false,
      number: null,
      currentGrossPercentage: 0,
      previousRateValue: 0,
      previousInGrossPay: false,
      earningCredential: {
        orgId: "",
        settingType: "earnings",
        payItemId: "",
        payItemSlug: "",
        payType: "",
        rateTypeSlug: "",
        rateValue: null,
        nameOnPayslip: "",
        includedInGrossPay: false,
        isTaxable: false,
        calculateProrata: false,
        showInPayslip: false,
        payOnRequest: false,
        payMonth: null,
        payrollCutoffDay: null,
        addsToDecember: false,
        has13MonthFixedPay: false,
        month13IsTaxable: false,
        month13RateType: null,
        month13RateValue: null,
        advanceEligibility: "confirmation",
        advanceEligibilityDays: null,
        advanceMonthlyLimit: null,
        advanceCutoffDate: null,
        isPartOfPension: true,
        advanceRepaymentDuration: null,
        advanceIsInterestBased: false,
        interest: 0,
        requiresApproval: false,
        isActive: true,
        includedInTotalEmpCost: true,
        employeeSetsPayStart: false,
        payStartDay: null,
        maximumEmployeeApplicableDays: null,
        requiresNoOutstandingLoans: null,
        levelsSettings: []
      },
      selectedPayItem: null,
      rateType: {},
      ratingType: [],
      activeTab: {},
      breadcrumbs: [
        {
          disabled: false,
          text: "Settings",
          href: "settings",
          id: "Settings"
        },
        {
          disabled: false,
          text: "Pay Settings",
          id: "Pay Settings"
        },
        {
          disabled: false,
          text: this.$route.params.id ? "Edit Earnings" : "New Earnings",
          id: "New Earnings"
        }
      ],
      grossPayArray: [
        { name: "Yes", radioName: "grossPay", value: true },
        { name: "No", radioName: "grossPay", value: false }
      ],
      taxableArray: [
        { name: "Yes", radioName: "taxable", value: true },
        { name: "No", radioName: "taxable", value: false }
      ],
      eligibilityArray: [
        { name: "On Appointment", radioName: "eligibility", value: true },
        { name: "On Confirmation", radioName: "eligibility", value: false }
      ],
      levels: [],
      hasLevels: false,
      approvalArray: [
        { name: "Yes", radioName: "approval", value: true },
        { name: "No", radioName: "approval", value: false }
      ],
      activeArray: [
        { name: "Yes", radioName: "active", value: true },
        { name: "No", radioName: "active", value: false }
      ],
      costArray: [
        { name: "Yes", radioName: "cost", value: true },
        { name: "No", radioName: "cost", value: false }
      ],
      earningArray: [
        { name: "Yes", radioName: "earning", value: true },
        { name: "No", radioName: "earning", value: false }
      ],
      basisArray: [
        { name: "Yes", radioName: "basis", value: true },
        { name: "No", radioName: "basis", value: false }
      ],
      paySlipArray: [
        { name: "Yes", radioName: "paySlip", value: true },
        { name: "No", radioName: "paySlip", value: false }
      ],
      contributionArray: [
        { name: "Yes", radioName: "contribution", value: true },
        { name: "No", radioName: "contribution", value: false }
      ],
      daysArray: [],
      monthsArray: [
        { name: "January", id: "1" },
        { name: " February", id: "2" },
        { name: " March", id: "3" },
        { name: " April", id: "4" },
        { name: " May", id: "5" },
        { name: " June", id: "6" },
        { name: " July", id: "7" },
        { name: " August", id: "8" },
        { name: " September", id: "9" },
        { name: " October", id: "10" },
        { name: " November", id: "11" },
        { name: "   December", id: "12" }
      ],
      requestArray: [
        { name: "On Employee Request", radioName: "request", value: "true" },
        { name: "Company Set Date", radioName: "request", value: "false" }
      ],
      rateTypes: [],
      payItemType: [
        { name: "Regular", id: "regular" },
        { name: "Variable", id: "variable" }
      ],
      error: [],
      rateValue: null,
      loading: true,
      initialPayslipName: "",
      payslipRule: [
        "required",
        {
          name: "payslip",
          rule: v => this.validatePayslipName(v)
        }
      ]
    };
  },
  methods: {
    async validatePayslipName(value) {
      if (this.initialPayslipName === value) {
        return true;
      }
      const result = await this.$_validateNameInPayslip({
        nameOnPayslip: value,
        orgId: this.$orgId
      });
      if (!result.data.payItemExist) {
        return true;
      }
      return "This name in Payslip has already been used";
    },
    handlePayableRequestChange(value) {
      if (value === "false") {
        this.earningCredential.payrollCutoffDay = null;
      } else {
        this.earningCredential.payMonth = null;
      }
    },
    convertValue(value) {
      if (value === "true" || value === true) {
        return true;
      }
      return false;
    },
    getPayItems() {
      this.$_getEarnings().then(result => {
        this.payItemList = result.data.payItems;
        this.ratingType = result.data.rates;
      });
    },
    async getLevels() {
      try {
        const response = await this.$_getLevels();
        const { levels } = response.data;

        // Hide levels card if there are no levels
        if (levels.length === 0) {
          this.hasLevels = false;
          return;
        }

        this.levels = levels.map(item => ({
          levelId: item.id,
          rateValue: null,
          rateType: null,
          orgId: this.$AuthUser.orgId,
          name: item.name,
          id: item.id,
          isAll: false
        }));

        const allLevels = {
          name: "All Levels",
          id: "0",
          isAll: true,
          rateValue: null,
          rateType: null
        };
        this.levels.unshift(allLevels);
        this.activeTab = allLevels;

        this.hasLevels = true;
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async handleLevel(tab) {
      try {
        this.activeTab = tab;

        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    updateLevelsType(value) {
      this.levels = this.levels.map(item => ({
        ...item,
        rateType: value
      }));
      this.activeTab.rateType = value;
    },
    async updateLevelsValue(value) {
      try {
        this.levels = this.levels.map(item => ({
          ...item,
          rateValue: value
        }));

        this.activeTab.rateValue = value;

        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    // eslint-disable-next-line consistent-return
    handlePayItem(value) {
      // if (!this.$route.params.id) {
      const change = this.payItemList.find(item => item.id === value);
      if (!this.$route.params.id) {
        this.earningCredential.nameOnPayslip = change.name;
      }
      this.earningCredential.payItemSlug = change.slug;

      // Assign Rate types value to the selected item Rate types
      this.rateTypes = change.rateTypes.map(item => ({
        name: item.label,
        id: item.slug
      }));

      // Enable request
      this.isDisabledRequest = false;
      // Handle case where Advance is selected
      if (change.slug === "advance" || change.slug === "loan") {
        this.activeTab.rateValue = this.advanceAndLoanLimit;
        this.rateValue = this.advanceAndLoanLimit;
        return this.handleAdvanceAndLoan();
      }
      if (change.slug === "basic") {
        return this.handleBasicSalary();
      }
      if (change.slug === "overtimeAllowance") {
        return this.handleovertimeAllowance();
      }
      this.payItemType = [
        { name: "Regular", id: "regular" },
        { name: "Variable", id: "variable" }
      ];
      if (!this.$route.params.id) {
        this.levels = this.levels.map(item => ({
          ...item,
          rateValue: this.advanceAndLoanLimit
        }));
        this.earningCredential.includedInGrossPay = false;
      }
      this.isPayRequest = "false";
      this.isDisabled = false;
      this.isVisible = false;
      this.dateVisible = "true";
      this.isDisabledRequest = true;
      // }
    },

    handleRateType(value) {
      const rateType = this.rateTypes.find(element => value === element.id);
      if (rateType) {
        this.updateLevelsType(rateType.name);
      }
    },
    // Set variable when Advance OR Loan is selected
    handleAdvanceAndLoan() {
      this.payItemType = [{ name: "Variable", id: "variable" }];
      this.earningCredential.payType = "variable";
      this.earningCredential.rateTypeSlug = "percentGrossEarn";
      this.handleRateType(this.earningCredential.rateTypeSlug);
      this.earningCredential.includedInGrossPay = false;
      this.isDisabled = true;
      this.isPayRequest = "true";
      this.isDisabledRequest = true;
      this.isVisible = true;
    },
    setAdvanceAndLoanLimit(value) {
      this.advanceAndLoanLimit = value;
      this.openAdvanceAndLoansSidebar = false;
    },
    async getAdvanceAndLoanLimit() {
      const response = await this.$_getGeneralAdvanceSettings();
      this.advanceAndLoanLimit = response.data.setting.maxLoanAndAdvanceRequest;
    },
    // Set Regular and Gross pay yes when Basic Salaryis selected
    handle13thMonth() {
      this.payItemType = [{ name: "Variable", id: "variable" }];
      this.earningCredential.includedInGrossPay = true;
      this.earningCredential.payType = "variable";
      this.isPayRequest = "false";
      this.isDisabled = false;
      this.isDisabledRequest = true;
      this.isVisible = false;
    },
    // Set Regular and Gross pay yes when Basic Salaryis selected
    handleBasicSalary() {
      this.payItemType = [{ name: "Regular", id: "regular" }];
      this.earningCredential.includedInGrossPay = true;
      this.earningCredential.payType = "regular";
      this.isPayRequest = false;
      this.isDisabled = true;
      this.isVisible = false;
    },

    handleovertimeAllowance() {
      this.payItemType = [{ name: "Variable", id: "variable" }];
      this.earningCredential.includedInGrossPay = false;
      this.earningCredential.payType = "variable";
      this.isPayRequest = "true";
      this.isDisabled = true;
      this.isDisabledRequest = "true";
      this.isVisible = false;
    },

    handleleaveAllowance() {
      this.isPayRequest = "true";
      this.isDisabled = "true";
      this.isDisabledRequest = "true";
      this.isVisible = false;
    },
    formatDate(date) {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join("-");
    },
    async saveEarnings() {
      if(!this.creatingEarnings){
      this.creatingEarnings = true;
      try {
        this.earningCredential.orgId = this.$AuthUser.orgId;
        this.earningCredential.rateValue = this.rateValue;
        this.earningCredential.payOnRequest = this.convertValue(
          this.isPayRequest
        );
        this.levels.shift();
        this.earningCredential.levelsSettings = this.levels.map(level => {
          let levels = {};

          levels = {
            orgId: level.orgId,
            levelId: level.levelId,
            rateValue: level.rateValue,
            requireApproval: false,
            parentCanOverride: true
          };
          return levels;
        });
        this.earningCredential.settingType = "earnings";
        if (this.earningCredential.advanceCutoffDate) {
          this.earningCredential.advanceCutoffDate = new Date(
            this.earningCredential.advanceCutoffDate
          ).getDate();
        }

        if (this.isEditState === true) {
          await this.$_updatePayItemSettings(this.earningCredential).then((result) => {
            this.$toasted.success(result.data.message, { duration: 5000 });
          })
        } else {
          await this.$_payItemSetting(this.earningCredential).then((result) => {
            this.$toasted.success(result.data.message, { duration: 5000 });
          })
        }

        this.$router.push({
          name: "PaySettings",
          query: { payType: "Earnings" }
        });
        this.rateValueWasChanged = false;
        this.creatingEarnings = false;
      } catch (error) {
        this.rateValueWasChanged = false;
        this.creatingEarnings = false;
        this.$toasted.error(error.message, { duration: 5000 });
        throw new Error(error);
      }
      }
    },
    changePayItem(value) {
      this.categories.map(element => {
        if (element.id === value) {
          this.payItemList = element.payItems;
        }
        return value;
      });
    },
    handlePayItemList(event) {
      this.earningCredential.advanceEligibilityDays = event.target.value;
    },
    setRateType(rateType) {
      this.earningCredential.rateTypeSlug = rateType;
      this.handleRateType(this.earningCredential.rateTypeSlug);
    },
    addAllLevelsTab() {
      const allLevels = {
        name: "All Levels",
        id: "0",
        isAll: true,
        rateValue: this.earningCredential.rateValue
      };
      this.levels.unshift(allLevels);
      this.activeTab = allLevels;
    },
    handleIsActive(){
      this.earningCredential.isActive = true
    }
  },
  computed: {
    disabledRateType() {
      if (this.earningCredential.includedInGrossPay.toString() === "true") {
        this.setRateType("percentGrossEarn");
        return true;
      }
      return false;
    },
    disableIsActive() {
      if(this.earningCredential.includedInGrossPay.toString() === 'true') {
        this.handleIsActive()
        return true
      }
      return false
    },
    disabledLevelRateValue() {
      return !!(
        this.activeTab.isAll ||
        this.earningCredential.payType === "regular" ||
        (this.earningCredential.payType === "variable" &&
          this.earningCredential.includedInGrossPay.toString() === "true")
      );
    }
  },
  watch: {
    rateValue(value) {
      if (this.earningCredential.includedInGrossPay.toString() === "true") {
        const gross = this.currentGrossPercentage;
        const totalGross = this.previousInGrossPay
          ? gross + (Number(value) - this.previousRateValue)
          : gross + Number(value);
        this.creatingEarnings = false;
        if (totalGross > 100) {
          this.creatingEarnings = true;
          this.error.push(
            `You can not exceed 100% gross earnings distributed across already added pay items. current % is ${totalGross}`
          );
        } else {
          this.error = [];
        }
      }
    },
    isAppointment(value) {
      if (value) {
        this.earningCredential.advanceEligibility = "appointment";
      } else {
        this.earningCredential.advanceEligibility = "confirmation";
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.isEditState = true;
    }
  },
  async mounted() {
    for (let i = 1; i < 29; i++) {
      this.daysArray.push({
        name: this.$getOrdinal(i),
        id: i
      });
    }
    await this.getPayItems();
    await this.getLevels();
    await this.getAdvanceAndLoanLimit();
    await this.$_getOrgGrossTotal().then(result => {
      this.currentGrossPercentage = Number(result.data.currentGross);
      this.loading = false;
    });
    if (this.$route.params.id) {
      this.loading = true;
      this.$_getOnePayItemSettings(this.$route.params.id).then(async result => {
        this.earningCredential = result.data.paySetting;
        this.initialPayslipName = this.earningCredential.nameOnPayslip;

        if (this.earningCredential.advanceEligibility === 'appointment') {
          this.isAppointment = true;
        }
        else {
          this.isAppointment = false;
        }

        this.rateValue = this.earningCredential.rateValue;
        this.previousRateValue = this.rateValue;
        this.previousInGrossPay = this.earningCredential.includedInGrossPay;
        this.rateTypes = [
          {
            name: this.earningCredential.rateType.label,
            id: this.earningCredential.rateType.slug
          }
        ];
        this.earningCredential.payItem.rateTypes = [
          this.earningCredential.rateType
        ];
        this.payItemList.push(this.earningCredential.payItem);
        this.handleRateType(this.earningCredential.rateTypeSlug);

        this.isPayRequest = this.earningCredential.payOnRequest.toString();

        // populate levels
        this.levelSettings = result.data.levels;
        this.levels = this.levelSettings;
        this.addAllLevelsTab();
        this.handlePayItem(this.earningCredential.payItemId);

        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss">
  .label label {
    font-size:10px;
  }
</style>